import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import CreateField from '../helpers/createField';
import { contentTypeChoices, objectFitChoices } from '../selectfieldchoices/narrowcastingselect.mjs';


function ViewForm(props) {
  const { t: translate } = useTranslation();
  const manualUrlInvalid = props.manualUrlInvalid;
  const [duration, setDuration] = useState(0);
  const updateDuration = (newDuration) => {
    props.data.seconds = newDuration;
    setDuration(newDuration);
  }
  
  if (props.data.hasOwnProperty('content_type')) {
    props.content_type.selected = { 'value': props.data.content_type, 'label': translate(contentTypeChoices.find(element => element.id === props.data.content_type)?.name) }
    switch (props.data.content_type) {
      case "Video":
        props.fields.find(field => field.id === 'video').type = 'text';
        props.fields.find(field => field.id === 'object_fit').type = 'select';
        props.fields.find(field => field.id === 'video').required = true;
        props.fields.find(field => field.id === 'object_fit').required = true;
        break;
      case "Image":
        props.fields.find(field => field.id === 'image').type = 'text';
        props.fields.find(field => field.id === 'object_fit').type = 'select';
        props.fields.find(field => field.id === 'seconds').type = 'number';
        props.fields.find(field => field.id === 'new_image_src').type = 'file';
        props.fields.find(field => field.id === 'image').required = true;
        props.fields.find(field => field.id === 'object_fit').required = true;
        props.fields.find(field => field.id === 'seconds').required = true;
        break;
      case "Youtube":
        props.fields.find(field => field.id === 'youtube_url').type = 'text';
        props.fields.find(field => field.id === 'seconds').type = 'number';
        props.fields.find(field => field.id === 'youtube_url').required = true;
        props.fields.find(field => field.id === 'seconds').required = true;
        break;
      default:
        props.fields.find(field => field.id === 'image').type = 'hidden';
        props.fields.find(field => field.id === 'video').type = 'hidden';
        props.fields.find(field => field.id === 'youtube_url').type = 'hidden';
        props.fields.find(field => field.id === 'object_fit').type = 'hidden';
        props.fields.find(field => field.id === 'seconds').type = 'hidden';
    }
  }

  if (props.data.hasOwnProperty('object_fit')) {
    props.object_fit.selected = { 'value': props.data.object_fit, 'label': objectFitChoices.find(element => element.id === props.data.object_fit)?.name }
  }
  
  if (props.data.hasOwnProperty('video') && props.data.content_type === 'Video') {
    props.fields.find(field => field.id === 'video').invalidFeedback = 'invalid URL'
    const video = document.createElement('video');
    video.src = props.data.video;
    //is used to automatically set view duration based on metadata, can also be used to provide feedback for valid video url
    video.addEventListener('loadedmetadata', event => {
      if (props.fields.find(field => field.id === 'video').invalid) {
        manualUrlInvalid(props.fields.find(field => field.id === 'video'), false)
      }
      video.remove();
      updateDuration(Math.trunc(video.duration));
    })
    //is used to set view duration to 0 when url is invalid, can also be used to provide additional feedback for invalid video url
    video.addEventListener('error', event => {
      if (!props.fields.find(field => field.id === 'video').invalid) {
        manualUrlInvalid(props.fields.find(field => field.id === 'video'), true)
      }
      video.remove()
      if (duration !== 0) {
        updateDuration(0);
      }
    })
  }

  if (props.data.hasOwnProperty('image') && props.data.content_type === 'Image') {
    if (props.data.image === '' || props.data.image === 'Uploading image please wait...') {
      if(props.fields.find(field => field.id === 'image').invalid){
        manualUrlInvalid(props.fields.find(field => field.id === 'image'), false)
      }
    } else {
      props.fields.find(field => field.id === 'image').invalidFeedback = 'invalid URL'
      const image = document.createElement('img');
      image.src = props.data.image;
      //can be used to provide feedback for valid image url
      image.addEventListener('load', event => {
        if (props.fields.find(field => field.id === 'image').invalid) {
          manualUrlInvalid(props.fields.find(field => field.id === 'image'), false)
        }
        image.remove();
      })
      //can be used to provide feedback for invalid image url
      image.addEventListener('error', event => {
        if (!props.fields.find(field => field.id === 'image').invalid) {
          manualUrlInvalid(props.fields.find(field => field.id === 'image'), true)
        }
        image.remove();
      })
    }
    
  }

  if (props.data.hasOwnProperty('seconds')) {
    if (duration !== props.data.seconds && props.data.seconds !== null) {
      setDuration(props.data.seconds);
    } else if (props.data.seconds === null && duration !== 0) {
      setDuration(0);
    }
  } else {
    if (duration !== 0) {
      updateDuration(null)
    }
  }

  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  props.fields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        embeddedfield.invalid = props.invalidFields.find(invalidField => invalidField.field === embeddedfield?.id) ? true : false;
        objectFields.push(CreateField(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    } else {
      field.invalid = props.invalidFields.find(invalidField => invalidField.field === field?.id) ? true : false;
      basicFields.push(CreateField(field, props))
    }
  });

  return (
    <>
      <Form>
        <Tab.Container defaultActiveKey="main">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
                <Form.Label>{translate('Duration')}: {duration}s</Form.Label>
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
      </Form>
      <Button variant="primary" type={props.cancel.type} onClick={props.cancel.onClick}>{translate(props.cancel.title)}</Button>
      <Button variant="primary" type={props.submit.type} disabled={props?.submit?.disabled} onClick={props.submit.onClick}>{translate(props.submit.title)}</Button>
    </>
  );
}

export default ViewForm;