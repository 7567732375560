export const NarrowcastingViewFormFields = () => {

  const NarrowcastingViewFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id' },
    { type: 'text', name: `Name`, id: 'name', required: true },
    { type: 'select', name: `Content Type`, id: 'content_type', formdatafield: 'content_type', required: true },
    { type: 'hidden', name: `Image URL`, id: 'image', readonly: false, invalidFeedback: 'Please fill in this field.', invalid: false, required: false },
    { type: 'hidden', name: `Upload Image`, id: 'new_image_src', accept: 'image/png', info: "Only PNG supported\n(8MB Max)" },
    { type: 'hidden', name: `Video URL`, id: 'video', readonly: false, invalidFeedback: 'Please fill in this field.', invalid: false, required: false },
    { type: 'hidden', name: `Youtube URL`, id: 'youtube_url', readonly: false, required: false },
    { type: 'hidden', name: `Scaling`, id: 'object_fit', formdatafield: 'object_fit', required: false },
    { type: 'hidden', name: `Seconds`, id: 'seconds', readonly: false, required: false },
  ]

  return NarrowcastingViewFormFields
}