import { createSlice } from "@reduxjs/toolkit";

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    sidebar: localStorage.getItem('sidebar') ? JSON.parse(localStorage.getItem('sidebar')) : {},
  },
  reducers: {
    setSidebar: (state, action) => {
      state.sidebar = action.payload;
      localStorage.setItem('sidebar', JSON.stringify(action.payload));
    },
    clearSidebar: (state) => {
      state.sidebar = {};
      localStorage.removeItem('sidebar');
    },
  }
});

export const {
  setSidebar, clearSidebar
} = sidebarSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getSidebar = (state) => state.sidebar.sidebar

export default sidebarSlice.reducer;