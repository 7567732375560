import { allScopes, resellerScopes } from "../scopes/scopes.mjs"
import CreateAdminRegistrationHashBasedOnAdminId from "./createAdminRegistrationHashBasedOnLocationId"
import CreateLocationRegistrationHashBasedOnAdminId from "./createLocationRegistrationHashBasedOnAdminId"
import RegistrationIsValid from "./RegistrationIsValid"

export default function CreateScopes({ role, admin_id, location_id, registrations, userroles, token, dispatch }) {
  let scopes = { NumberOfDevices: 0 };

  switch (role) {
    case 'super':
    case 'admin':
      _createScopes({ registrationScopes: allScopes, scopes });
      break;
    case 'reseller':
      _createScopes({ registrationScopes: resellerScopes, scopes });
      break;
    case 'merchant':
      _createMerchantScopes({ registrations, admin_id, location_id, token, dispatch, scopes });
      break;
    case 'user':
      if (userroles) {
        _createUserScopes({ scopes, userroles, admin_id, location_id });
      }
      break;
    default:
      break;
  }

  return scopes;
}

function _createMerchantScopes({ registrations, admin_id, location_id, token, dispatch, scopes }) {
  if (!registrations) {
    scopes.subscribe = 1;
    return;
  }

  let registrationToUse = location_id
    ? CreateLocationRegistrationHashBasedOnAdminId(registrations)?.[location_id]
    : CreateAdminRegistrationHashBasedOnAdminId(registrations)?.[admin_id];

  if (registrationToUse) {
    for (const registration of registrationToUse.registrations) {
      if (
        registration?.subscription?.scopes &&
        RegistrationIsValid({ token, setting: registration, dispatch })
      ) {
        _createScopes({ registrationScopes: registration.subscription.scopes, scopes });
      }
    }
  } else {
    scopes.subscribe = 1;
  }
}

function _createScopes({ registrationScopes, scopes }) {
  const scopeOptions = ['read', 'create', 'update', 'delete'];

  for (const scope of registrationScopes) {
    const splitted = scope.split(':');
    const name = splitted.at(-2);
    const value = splitted.at(-1);
    const index = scopeOptions.indexOf(value);

    _addToScope({ scopes, name, value, index });
  }
}

function _createUserScopes({ scopes, userroles, admin_id, location_id }) {
  for (const role of userroles) {
    const splitted = role.split(':');
    const [type, roleAdminId, roleLocationId] = splitted;
    const name = splitted.at(-2);
    const value = splitted.at(-1);
    const index = ['read', 'create', 'update', 'delete'].indexOf(value);

    if (type === 'portal' && roleAdminId === admin_id && (!location_id || roleLocationId === location_id)) {
      _addToScope({ scopes, name, value, index });
    }
  }
}

function _addToScope({ scopes, name, value, index }) {
  if (name === 'NumberOfDevices') {
    scopes.NumberOfDevices = (scopes.NumberOfDevices || 0) + Number(value);
  } else {
    scopes[name] = Math.max(scopes[name] || 0, index);
  }
}