import { createSlice } from "@reduxjs/toolkit";

export const administrationSlice = createSlice({
  name: "administration",
  initialState: {
    administration_pagesize: 10,
    administration_pagination: 0,
    administrations_all: localStorage.getItem('administrations_all') ? JSON.parse(localStorage.getItem('administrations_all')) : null,
    hash_administrations_all: {},
  },
  reducers: {
    setAdministrationId: (state, action) => {
      state.admin_id = action.payload;
    },
    setAdministration: (state, action) => {
      state.administration = action.payload;
    },
    setAdministrations: (state, action) => {
      state.administrations = action.payload
    },
    setAdministrationsAll: (state, action) => {
      state.administrations_all = action.payload
      if (action.payload) localStorage.setItem('administrations_all', JSON.stringify(action.payload));
      action?.payload?.forEach(admin => {
        state.hash_administrations_all[admin.id] = admin
      });
    },
    setAdministrationModalShow: (state, action) => {
      state.administration_modalshow = action.payload
    },
    setAdministrationPagination: (state, action) => {
      state.administration_pagination = (action.payload - 1)
    },
    setAdministrationPagesize: (state, action) => {
      state.administration_pagesize = action.payload
    },
    setAdministrationRegistrations: (state, action) => {
      state.administration_registrations = action.payload
    },
    setSearch: (state, action) => {
      state.search = action.payload
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    },
    clearReduxAdministration: state => {}
  }
});

export const {
  setAdministrationId, 
  setAdministration, 
  setAdministrations, 
  setAdministrationModalShow, 
  setAdministrationPagination, 
  setAdministrationPagesize, 
  setAdministrationsAll, 
  setAdministrationRegistrations,
  setFilter, 
  setSearch,
  clearReduxAdministration
} = administrationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getAdministrationId = (state) => state.administration.admin_id
export const getAdministration = (state) => state.administration.administration
export const getAdministrations = (state) => state.administration.administrations
export const getAdministrationsAll = (state) => state.administration.administrations_all
export const getHashAdministrationsAll = (state) => state.administration.hash_administrations_all
export const getAdministrationModalShow = (state) => state.administration.administration_modalshow
export const getAdministrationsPagination = (state) => state.administration.administration_pagination
export const getAdministrationsPagesize = (state) => state.administration.administration_pagesize
export const getAdministrationRegistrations = (state) => state.administration.administration_registrations
export const getFilter = (state) => state.administration.filter
export const getSearch = (state) => state.administration.search

export default administrationSlice.reducer;