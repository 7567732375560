import { useTranslation } from 'react-i18next';
import Select from 'react-select';

function SelectField(props) {
  const { t: translate } = useTranslation();
  const options = props?.options || [];
  const selected = props.selected?.id ? { value: props.selected.id, label: props.selected.name } : null
  return (
    <Select
      aria-label={props.label}
      onChange={props.onChange}
      width={props.width}
      className={`basic-multi-select ${props?.className}`}
      options={
        options.length > 0 ? options?.map((option) => ({ value: option.id, label: option.name })) : []
      }
      value={selected ?? null}
      defaultValue={selected ?? null}
      placeholder={
        props?.placeholder
          ? <div className='select-placeholder'>{translate(props.placeholder)}</div>
          : translate('Select...')
      }
      isClearable={props?.clearable}
      isSearchable={props?.searchable}
      isLoading={props?.loading}
      isDisabled={props?.disabled}
      menuPortalTarget={document.querySelector('body')}
      loadingMessage={() => (props.loading ? 'Loading...' : null)}
    />
  );
}

export default SelectField;