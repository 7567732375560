import { createSlice } from "@reduxjs/toolkit";

export const selectAdminSlice = createSlice({
  name: "selectAdmin",
  initialState: {
    selected_admin_id: localStorage.getItem('admin_id') !== 'undefined' ? JSON.parse(localStorage.getItem('admin_id')) : undefined,
    selected_admin: localStorage.getItem('admin') !== 'undefined' ? JSON.parse(localStorage.getItem('admin')) : undefined,
    hash_selected_admin_registration: {},
  },
  reducers: {
    setSelectedAdminId: (state, action) => {
      if (action.payload) {
        state.selected_admin_id = action.payload
        localStorage.setItem('admin_id', JSON.stringify(action.payload));
      }
      if (!action.payload) {
        state.selected_admin_id = action.hasOwnProperty('payload') ? action?.payload?.value : undefined;
        localStorage.setItem('admin_id', JSON.stringify(action.hasOwnProperty('payload') ? action?.payload?.value : undefined));
      }
    },

    setSelectedAdmin: (state, action) => {
      if (action.payload) {
        state.selected_admin = action.payload
        localStorage.setItem('admin', JSON.stringify(action.payload));
      }
      if (!action.payload) {
        state.selected_admin = action.hasOwnProperty('payload') ? action?.payload?.value : undefined;
        localStorage.setItem('admin', JSON.stringify(action.hasOwnProperty('payload') ? action?.payload?.value : undefined));
      }
    },
    setSelectedAdminRegistration: (state, action) => {
      state.selected_admin_registration = action.payload
      action?.payload?.forEach(registration => {
        registration.settings.forEach(setting => {
          if (!state.hash_selected_admin_registration[setting.administration_id]) {
            state.hash_selected_admin_registration[setting.administration_id] = {}
            state.hash_selected_admin_registration[setting.administration_id]['registrations'] = []
          }
          state.hash_selected_admin_registration[setting.administration_id]['registrations'].push(setting)
          let type = setting.subscription.type.type
          state.hash_selected_admin_registration[setting.administration_id][type] = true
        });
      });
    },
    setSelectedAdminScopes: (state, action) => {
      state.selected_admin_scopes = action.payload
    }
  }
});

export const {
  setSelectedAdminId, setSelectedAdmin, setSelectedAdminRegistration, setSelectedAdminScopes
} = selectAdminSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getSelectedAdmin_id = (state) => state.selectAdmin.selected_admin_id
export const getSelectedAdmin = (state) => state.selectAdmin.selected_admin
export const getSelectedAdminRegistration = (state) => state.selectAdmin.selected_admin_registration
export const getSelectedAdminScopes = (state) => state.selectAdmin.selected_admin_scopes

export default selectAdminSlice.reducer;