import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getToken, setToken } from "../../redux/dataSlices/tokenSlice";
import { clearSidebar, getSidebar } from "../../redux/dataSlices/sidebarSlice";
import { ReactSVG } from "react-svg";
import { Col } from "react-bootstrap";
import "./sidebar.css"
import { useTranslation } from "react-i18next";
import Accordion from 'react-bootstrap/Accordion';
// import Logo from "../../static/icons/LogoIcon.svg";
// import LogoFull from "../../static/smallerFullLogo.svg";
import Home from "../../static/icons/home.svg";
import Relations from "../../static/icons/relations.svg";
import Orders from "../../static/icons/orders.svg";
import Transactions from "../../static/icons/transactions.svg";
import Products from "../../static/icons/products.svg";
import Bee from "../../static/icons/bee.svg";
import Settings from "../../static/icons/settings.svg";
import User from "../../static/icons/user.svg";
import Reservations from "../../static/icons/reservations.svg";
import Overview from "../../static/icons/overview.svg";
import Import from "../../static/icons/import.svg";
import Super from "../../static/icons/super.svg";
import Logout from "../../static/icons/logout.svg";
import NoIcon from "../../static/icons/replace.svg";
import Narrowcasting from "../../static/icons/narrowcasting.svg";
import Analyze from "../../static/icons/analyze.svg";
import KitchenManager from "../../static/icons/kitchenmanager.svg";
import Planning from "../../static/icons/planning.svg";
import Docs from "../../static/icons/docs.svg";
import Stock from "../../static/icons/stock.svg";
import { clearReduxAll } from "../../redux/dataSlices/loggedInUserSlice";
import LogoutKeycloakUser from "../../actablueAPI/keycloak/logoutKeycloakUser";
import useHandleError from "../../customhooks/useHandleError";
import { setAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { setLocationsAll } from "../../redux/dataSlices/locationSlice";


const Sidebar = () => {
  const sidebar = useSelector(getSidebar);
  const token = useSelector(getToken)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [links, setLinks] = useState([])
  const [isOpen, setIsOpen] = useState(false);
  const { t: translate } = useTranslation();
  const HandleError = useHandleError();

  const iconList = {
    home: Home,
    reservations: Reservations,
    orders: Orders,
    transactions: Transactions,
    products: Products,
    bee: Bee,
    settings: Settings,
    user: User,
    narrowcasting: Narrowcasting,
    logout: Logout,
    noicon: NoIcon,
    '1settings': Home,
    '2relations': Relations,
    '3products': Products,
    '4transactions': Transactions,
    '5reservations': Reservations,
    '6overview': Overview,
    '7import': Import,
    '8analyse': Analyze,
    '9narrowcasting': Narrowcasting,
    '10kitchenmanager': KitchenManager,
    '11workorder': Planning,
    '12warehouse': Stock,
    '99super': Super,
    'docs+status': Docs
  }

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    const accordionButtons = document.querySelectorAll('.accordion-button');
    accordionButtons.forEach(button => {
      if (button.getAttribute('aria-expanded') === 'true') {
        button.click();
      }
    });
  };

  const onClickLogout = () => {
    LogoutKeycloakUser({ token: token })
      .then(() => {
        dispatch(clearSidebar());
        dispatch(setAdministrationsAll(null));
        dispatch(setLocationsAll(null));
        dispatch(clearReduxAll())
        dispatch(setToken(undefined));
        navigate("/");
      })
      .catch(error => {
        HandleError({ error: error })
      });

  }

  useEffect(() => {
    let createdLinks = [];
    let eventKey = 0;
    const headlink = {
      '1settings': '/dashboards',
      '2relations': '/relations',
      '3products': '/products',
      '4transactions': '/transactions',
      '5reservations': '/reservations',
      '6overview': '/reporting',
      '7import': '/importing',
      '8analyse': '/analyses',
      '9narrowcasting': '/narrowcastingdevices',
      '10kitchenmanager': '/kitchenmanager/devices',
      '11workorder': '/planning',
      '12warehouse': '/stock',
      '99super': '/searchid'
    }
    const subLink = {
      '10kitchenmanager': {
        'printers': {
          'link': '/kitchenmanager/devices',
          'hidden': true
        },
        'screens': {
          'link': '/kitchenmanager/devices',
          'hidden': true
        },
        'settings': {
          'link': '/kitchenmanager/devices',
          'hidden': true
        },
        'kmdevices': {
          'tag': 'devices',
          'link': '/kitchenmanager/devices',
          'hidden': false
        },
        'sortcategories': {
          'tag': 'sortcategories',
          'link': '/kitchenmanager/sortcategories',
          'hidden': false
        },
        'resellers': {
          'tag': 'resellers',
          'link': '/kitchenmanager/resellers',
          'hidden': false
        },
      }
    }
    if (sidebar) {
      const ordered = Object.keys(sidebar).sort((a, b) => {
        if (Number(a.replace(/\D/g, '')) < Number(b.replace(/\D/g, ''))) return -1
        if (Number(a.replace(/\D/g, '')) > Number(b.replace(/\D/g, ''))) return 1
        return 0
      })

      for (const mainitem of ordered) {

        let links = []
        for (let subItemObject of Object.values(sidebar[mainitem])) {
          let link = subItemObject.link
          let tag = subItemObject.tag
          if (subLink[mainitem]) {
            if (subLink[mainitem][subItemObject.tag]) {
              if (subLink[mainitem][subItemObject.tag].hidden) continue
              link = subLink[mainitem][subItemObject.tag].link || subItemObject.link
              tag = subLink[mainitem][subItemObject.tag].tag || subItemObject.tag
            }
          }
          links.push(
            <li key={subItemObject.tag}>
              <Link className='sidebar-link' to={link}>
                <p>
                  {translate(tag.charAt(0).toUpperCase() + tag.slice(1))}
                </p>
              </Link>
            </li>
          )
        }

        let clearifiedMainItem = mainitem.replace(/\d+/g, '')
        createdLinks.push(
          <li className='sidebar-item-accordion' key={mainitem}>
            <Link className='sidebar-link' to={headlink[mainitem]}>
              <ReactSVG className='sidebar-svg' src={iconList[mainitem] ? iconList[mainitem] : iconList['noicon']} />
            </Link>
            <Accordion.Item className={`sidebar-link ${isOpen ? "show" : "hide"}`} eventKey={eventKey}>
              <Accordion.Header className={`sidebar-item-text ${isOpen ? "show" : "hide"}`}>
                <h6 className={`sidebar-item-text ${isOpen ? "show" : "hide"} `}>
                  {translate(clearifiedMainItem.charAt(0).toUpperCase() + clearifiedMainItem.slice(1))}
                </h6>
              </Accordion.Header>
              <Accordion.Body>
                <ul key={mainitem}>
                  {links}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </li>
        )
        eventKey++;
      };
    }
    setLinks(createdLinks)
    // eslint-disable-next-line
  }, [sidebar, isOpen]);

  const bottomLinks = [
    <li className='sidebar-item' key={'bottomLinks'}>
      <Link>
        <ReactSVG className='sidebar-svg' src={Logout} onClick={onClickLogout} />
      </Link>
      <Accordion.Item className={`sidebar-link ${isOpen ? "show" : "hide"}`}>
        <Accordion.Header className={`sidebar-item-text ${isOpen ? "show" : "hide"}`}>
          <h6 className={`sidebar-item-text ${isOpen ? "show" : "hide"} `} onClick={onClickLogout}>
            {translate('Logout')}
          </h6>
        </Accordion.Header>
      </Accordion.Item>
    </li>
  ]

  const topLinks = [
    <li className='sidebar-item-accordion' key={'docs+status'}>
      <a target='_blank' rel='noopener noreferrer' href="https://docs.actasmart.com">
        <ReactSVG className='sidebar-svg' src={iconList['docs+status'] ? iconList['docs+status'] : iconList['noicon']} />
      </a>
      <Accordion.Item className={`sidebar-link ${isOpen ? "show" : "hide"}`} eventKey={'-1'}>
        <Accordion.Header className={`sidebar-item-text ${isOpen ? "show" : "hide"}`}>
          <h6 className={`sidebar-item-text ${isOpen ? "show" : "hide"} `}>
            {translate('Documents and status')}
          </h6>
        </Accordion.Header>
        <Accordion.Body>
          <ul key={'docs+status'}>
            <li key={'docs'}><a target='_blank' rel='noopener noreferrer' href="https://docs.actasmart.com"><p>{translate('Documentation')}</p></a></li>
            <li key={'statusAS'}><a target='_blank' rel='noopener noreferrer' href="https://uptime.actablue.com/status/actasmart"><p>{translate('Status Actasmart')}</p></a></li>
            <li key={'statusMSP'}><a target='_blank' rel='noopener noreferrer' href="https://status.multisafepay.com"><p>{translate('Status Multisafepay')}</p></a></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
    </li>
  ]


  return (
    <Col md={3} className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button className='sidebar-toggle' onClick={toggleSidebar}>
        <div id="SidebarIcon" className={`main-logo ${isOpen ? "show" : "show"}`} onClick={toggleSidebar} />
        <div id="SidebarLogo" className={`main-logo ${isOpen ? "show" : "hide"}`} onClick={toggleSidebar} />
      </button>
      <div className="d-grid gap-2">
        <div className='col-6'>
          <ul className='sidebar-list'>
            <Accordion className="sidebar-accordion" defaultActiveKey="0" flush>
              {topLinks}
              {links}
            </Accordion>
          </ul>
          <ul className='sidebar-list-bottom'>
            {bottomLinks}
          </ul>
        </div>
      </div>
    </Col>
  );
};
export default Sidebar;
