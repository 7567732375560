import { allScopes, resellerScopes } from "../scopes/scopes.mjs"
import RegistrationIsValid from "./RegistrationIsValid"
import CreateAdminRegistrationHashBasedOnAdminId from "./createAdminRegistrationHashBasedOnLocationId"
import CreateLocationRegistrationHashBasedOnAdminId from "./createLocationRegistrationHashBasedOnAdminId"

export default function CreateSidebar({ role, admin_id, location_id, registrations, userroles, token, dispatch }) {
  let sidebar = {};

  switch (role) {
    case 'super':
    case 'admin':
      _createSidebar({ registrationScopes: allScopes, sidebar });
      break;
    case 'reseller':
      _createSidebar({ registrationScopes: resellerScopes, sidebar });
      break;
    case 'merchant':
      _createMerchantSidebar({ registrations, admin_id, location_id, token, dispatch, sidebar });
      break;
    case 'user':
      if (userroles) {
        _createUserSidebar({ sidebar, userroles, admin_id, location_id });
      }
      break;
    default:
      break;
  }
  return sidebar;
}

function _createMerchantSidebar({ registrations, admin_id, location_id, token, dispatch, sidebar }) {
  if (!registrations) {
    sidebar['1settings'] = { subscribe: { tag: 'subscribe', link: 'subscribe' } };
    return;
  }

  let registrationSettingsToUse = location_id ? CreateLocationRegistrationHashBasedOnAdminId(registrations)?.[location_id] : CreateAdminRegistrationHashBasedOnAdminId(registrations)?.[admin_id];

  if (registrationSettingsToUse) {
    for (const registration of registrationSettingsToUse.registrations) {
      if (registration?.subscription?.scopes &&
        RegistrationIsValid({ token, setting: registration, dispatch })) {
        _createSidebar({ registrationScopes: registration.subscription.scopes, sidebar });
      }
    }
  } else {
    sidebar['1settings'] = { subscribe: { tag: 'subscribe', link: 'subscribe' } };
  }
}

function _createSidebar({ registrationScopes, sidebar }) {
  for (const scope of registrationScopes) {
    _addToSidebar({ sidebar, splitted: scope.split(':') });
  }
}

function _createUserSidebar({ sidebar, userroles, admin_id, location_id }) {
  for (const role of userroles) {
    const [type, roleAdminId, roleLocationId] = role.split(':');

    if (type === 'portal' && roleAdminId === admin_id && (!location_id || roleLocationId === location_id)) {
      _addToSidebar({ sidebar, splitted: role.split(':') });
    }
  }
}

function _addToSidebar({ sidebar, splitted }) {
  if (splitted[0] !== 'portal') return;

  const { sidebarMain, sidebarSub, tag, link } = getSidebarDetails(splitted);

  if (sidebarMain) {
    sidebar[sidebarMain] = sidebar[sidebarMain] || {};
    sidebar[sidebarMain][sidebarSub] = sidebarSub === 'schapkaartjes' ? { tag: 'shelflabels', link: 'shelflabels' } : { tag, link };
  }
}

function getSidebarDetails(splitted) {
  return splitted.length === 4
    ? { sidebarMain: splitted[1], sidebarSub: splitted[2], tag: splitted[2], link: splitted[2] }
    : { sidebarMain: splitted[3], sidebarSub: splitted[4], tag: splitted[4], link: splitted[4] };
}